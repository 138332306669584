@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    @import "https://fonts.googleapis.com/css2?family=Material+Symbols+Rounded:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";
    @import "https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200";
    @font-face {
        font-family: 'Comforter Brush';
        src: url("src/assets/fonts/ComforterBrush-Regular.ttf") format("truetype");
    }
}